/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import { prettyPrintJson, FormatOptions } from 'pretty-print-json';

@Pipe({ name: 'prettyJson' })
export class PrettyJsonPipe implements PipeTransform {
  transform(data: any, options?: FormatOptions): string {
    return prettyPrintJson.toHtml(data, options);
  }
}
