/** @format */

import { PropertyMap } from 'model-mapper';
import { Embedded } from '../_decorators/embedded-class.decorator';
import { Color } from './color.class';

@Embedded()
export class EmbeddedOrganizationalUnitKind {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Color })
  color: Color;

  @PropertyMap({ default: null })
  parent: string;
}
