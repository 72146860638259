/** @format */

import { PropertyMap } from 'model-mapper';
import { Module } from '../_constants/module';
import { Embedded, EmbeddedClass } from '../_decorators/embedded-class.decorator';
import { S3File } from './s3-file.class';

@Embedded({ sortFields: 'name' })
export class EmbeddedOrganization extends EmbeddedClass {
  @PropertyMap()
  public _id: string;

  @PropertyMap()
  public customerAccountId: string;

  @PropertyMap()
  public name: string;

  @PropertyMap({ type: S3File })
  public logo: S3File;

  @PropertyMap({ default: [] })
  public modules: Module[];
}
