/** @format */

import 'reflect-metadata';

export abstract class ModelClass {
  abstract _id: string;
  static sortFields?: string;
}

export interface IModelOptions {
  sortFields: string;
}

export function Model(options?: IModelOptions): ClassDecorator {
  return (target: any) => {
    target.sortFields = options?.sortFields;
  };
}
