/** @format */

import { PropertyMap } from 'model-mapper';
import { Embedded, EmbeddedClass } from '../_decorators/embedded-class.decorator';
import { embeddedMap } from '../_helpers/class.helper';

@Embedded({ sortFields: 'name' })
export class EmbeddedCustomerAccount extends EmbeddedClass {
  @PropertyMap()
  public _id: string;

  @PropertyMap()
  public name: string;

  @PropertyMap()
  public slug: string;

  @PropertyMap({ map: embeddedMap })
  public ownerId: string;
}
