/** @format */

import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { map } from 'lodash-es';
import moment, { DurationInputArg2 } from 'moment';
import { MouvementDataService } from '../../../_services/mouvement-data.service';
import { ChartComponent } from '../chart/chart.component';

export type DrilldowGrouping = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | null;

@Component({
  selector: 'app-mouvement-chart',
  templateUrl: './mouvement-chart.component.html',
  styleUrls: ['./mouvement-chart.component.scss'],
})
export class MouvementChartComponent implements OnInit, OnDestroy {
  @Input()
  equipmentId: string;

  chartOptions: any = {
    chart: {
      type: 'column',
      events: {
        drilldown: (event: any) => this.triggerMouvementChartDrilldown(event.point.options, event.point),
        drillup: (event: any) => this.triggerMouvementChartDrillup(event),
      },
    },
    credits: { enabled: false },
    title: { text: '' },
    legend: { enabled: false },
    xAxis: {
      type: 'category',
      crosshair: true,
    },
    yAxis: {
      title: { enabled: false },
      min: 0,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    drilldown: {
      breadcrumbs: {
        floating: true,
        buttonTheme: {
          style: { color: 'var(--app-color-secondary)' },
          states: { select: { style: { color: 'var(--app-color-primary)' } } },
        },
        position: {
          align: 'right',
          x: -25,
          // y: -50,
        },
        formatter: (data: any) => {
          return data.level === 0
            ? `${moment(this.mouvementChartDrilldownRoot).year()}`
            : `${moment(data.levelOptions.name).format(
                this.drilldownOptions[data.levelOptions.drilldown]?.breadcrumbsDateFormat,
              )}`;
        },
      },
      allowPointDrilldown: true,
      activeAxisLabelStyle: {
        color: 'var(--app-color-primary)',
        textDecoration: 'none',
      },
    },
  };
  private mouvementChart: ChartComponent | undefined;
  private mouvementChartDrilldownRoot: string | number | undefined;
  private drilldownOptions: any = {
    year: {
      drilldown: 'year',
      format: 'MMMM',
      grouping: 'month',
      dateFormat: 'YYYY',
      breadcrumbsDateFormat: 'YYYY',
      type: 'column',
    },
    month: {
      drilldown: 'month',
      format: 'DD',
      grouping: 'day',
      dateFormat: 'L',
      breadcrumbsDateFormat: 'MM',
      type: 'column',
    },
    day: {
      drilldown: 'day',
      format: 'HH[h]',
      grouping: 'hour',
      dateFormat: 'L HH[h]',
      breadcrumbsDateFormat: 'DD',
      type: 'column',
    },
    hour: {
      drilldown: 'hour',
      format: 'mm[min]',
      grouping: 'minute',
      dateFormat: 'L HH:mm',
      breadcrumbsDateFormat: 'HH[h]',
      type: 'column',
    },
    minute: {
      drilldown: 'minute',
      format: 'ss[s]',
      grouping: 'second',
      dateFormat: 'L HH:mm:ss',
      breadcrumbsDateFormat: 'mm[min]',
      type: 'area',
    },
  };

  private mouvementDataService = inject(MouvementDataService);

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  chart: ChartComponent;
  async loadData(chart: ChartComponent) {
    this.chart = chart;
    const from = moment().startOf('month').subtract(11, 'month').valueOf();
    await this.triggerMouvementChartDrilldown({
      drilldown: 'year',
      name: from,
      tickOption: { amount: 12, unit: 'month' },
    });

    const month = moment().startOf('month');
    let p = this.chart.chart?.series[0].data[this.chart.chart?.series[0].data.length - 1];
    await this.triggerMouvementChartDrilldown(
      {
        drilldown: 'month',
        name: month.valueOf(),
        tickOption: { amount: month.daysInMonth(), unit: 'day' },
      },
      p,
    );

    const day = moment().startOf('day');
    p = this.chart.chart?.series[0].data[day.date() - 1];
    await this.triggerMouvementChartDrilldown(
      {
        drilldown: 'day',
        name: day.valueOf(),
        tickOption: { amount: 24, unit: 'hour' },
      },
      p,
    );
  }

  private async triggerMouvementChartDrillup(event: any) {
    this.chart!.setXCategories(event.seriesOptions.categories, false);
  }

  private async triggerMouvementChartDrilldown(
    info: { drilldown: DrilldowGrouping; name: number; tickOption: { unit: DurationInputArg2; amount: number } },
    point?: Highcharts.Point | null,
  ) {
    const options = this.drilldownOptions[info.drilldown!];
    if (!options) return;
    this.chart!.setLoading(true);
    this.mouvementChartDrilldownRoot = info.name;
    const from = info.tickOption
      ? moment(info.name).startOf(info.tickOption.unit)
      : moment(info.name).startOf(options.drilldown);
    const to = info.tickOption
      ? from.clone().add(info.tickOption.amount, info.tickOption.unit)
      : from.clone().endOf(options.drilldown);
    const data = await this.mouvementDataService.getMouvementChartData({
      equipmentId: this.equipmentId,
      from: from.toDate(),
      to: to.toDate(),
      grouping: { unit: options.grouping },
    });
    const categories = data.map((d) => moment(d.name).format(options.format));
    this.chart!.setXCategories(categories);
    const serie: Highcharts.SeriesOptionsType & { categories: string[] } = {
      name: 'mouvement',
      type: options.type,
      step: options.type === 'area' ? 'left' : undefined,
      color: '#3f5264',
      categories,
      tooltip: {
        headerFormat: ``,
        pointFormatter: function (this: any): string {
          return $localize`
                  <span style="font-size: 0.8em;">${moment(this.name).format(options.dateFormat)}</span></br>
                  <span style="color: var(--app-color-primary)">${this.y} Mouvement${this.y! > 1 ? 's' : ''}</span>`;
        },
      },
      data: map(data, (d: any) => ({
        name: d.name,
        y: d.value as number,
        // x: findIndex(categories, (c) => moment(d.name).format(options.format) === c),
        // category: moment(d.name).format(options.format),
        drilldown: this.drilldownOptions[options.grouping] ? options.grouping : undefined,
      })),
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, '#3f5264'],
          [1, 'rgba(33, 105, 142, 0)'],
        ],
      },
    } as any;
    // (await this.mouvementChart?.getXAxis())?.update({ max: categories.length - 1 });
    // (await this.mouvementChart?.getXAxis())?.update({ min: 0, max: categories.length - 1 });
    if (point) this.chart!.addSeriesAsDrilldown(point, serie);
    else this.chart!.setSeries([serie]);
    this.chart!.setLoading(false);
  }
}
