/** @format */

import { PropertyMap } from 'model-mapper';
import { RealEstateStructureFamily } from '../_constants/real-estate-structure-family';
import { Embedded, EmbeddedClass } from '../_decorators/embedded-class.decorator';
import { embeddedMap } from '../_helpers/class.helper';
import { Location } from './location.class';
import { EmbeddedRealEstateStructureKind } from './real-estate-structure-kind.embedded.class';

@Embedded({ sortFields: 'kind.name name' })
export class EmbeddedRealEstateStructure extends EmbeddedClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  family: RealEstateStructureFamily;

  @PropertyMap({ type: EmbeddedRealEstateStructureKind })
  kind: EmbeddedRealEstateStructureKind;

  @PropertyMap({ map: embeddedMap })
  organizationalUnitId: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  parent: string;

  @PropertyMap()
  path: string;
}
