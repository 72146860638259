/** @format */

import { PropertyMap } from 'model-mapper';
import { Embedded, EmbeddedClass } from '../_decorators/embedded-class.decorator';

@Embedded({ sortFields: 'firstname lastname email' })
export class EmbeddedUser extends EmbeddedClass {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  sub: string;

  @PropertyMap()
  email: string;

  @PropertyMap()
  firstname: string;

  @PropertyMap()
  lastname: string;

  @PropertyMap()
  phone: string;
}
