/** @format */

import { inject, Injectable } from '@angular/core';
import { Moment } from 'moment';
import { from, Observable } from 'rxjs';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { SensorMetric } from '../_constants/sensor/sensor-metric';
import { HttpService } from './http.service';

export interface ISpeedChartData {
  timestamp: number;
  min: number;
  max: number;
  avg: number;
}

export type DrilldowGrouping = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | null;

export interface ISensorDataFilter {
  sensorId?: string;
  metric?: SensorMetric;
  from?: Date | Moment;
  to?: Date | Moment;
  grouping?: { unit: string };
}

@Injectable({
  providedIn: 'root',
})
export class SensorDataService {
  path = '/sensor-data';
  httpService: HttpService = inject(HttpService);

  datatable(query: IDatatableOptions, filter?: ISensorDataFilter): Observable<any> {
    const path = `${this.path}/datatable`;
    return from(this.httpService.post(path, { query, filter: this.getFilterQuery(filter) }));
  }

  getFilterQuery(filter?: ISensorDataFilter): any {
    const query: any = {};
    if (filter?.sensorId) query.sensorId = filter.sensorId;
    if (filter?.metric) query.metric = filter.metric;
    if (filter?.from) query.from = filter.from.toISOString();
    if (filter?.to) query.to = filter.to.toISOString();
    if (filter?.grouping) query.grouping = filter.grouping;
    return query;
  }

  async getBoilerRoomChartData(
    from?: Moment,
    to?: Moment,
  ): Promise<{
    dju: [number, number][];
    outsideTemperature: [number, number, number, number, number][];
    indoorTemperature: [number, number][];
    indoorHumidity: [number, number][];
    ecsStart: [number, number, number, number, number][];
    heatingStart: [number, number][];
    primaryEnergy: [number, number][];
    consumption: [number, number][];
  }> {
    const path = `/sensor-data/boiler-room-chart-data`;
    const query = { from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async getIndoorInfo(from?: Moment, to?: Moment): Promise<any[]> {
    const path = `/sensor-data/indoor-info`;
    const query = { from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async getKinds(kinds: string[], from?: Moment, to?: Moment): Promise<any> {
    const path = `/sensor-data/kinds`;
    const query = { kinds, from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async weartherChart(
    from?: Moment,
    to?: Moment,
  ): Promise<{
    temperatures: Array<{ x: number; y: number; weatherCode: number }>;
    precipitations: Array<{ x: number; y: number }>;
    pressures: Array<{ x: number; y: number }>;
    winds: Array<{ x: number; y: number }>;
    windgusts: Array<{ x: number; y: number }>;
  }> {
    const path = `/sensor-data/weather-chart`;
    const query: any = {};
    if (from) query.from = from.toISOString();
    if (to) query.to = to.toISOString();
    return await this.httpService.get(path, query);
  }
}
