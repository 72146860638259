<div *ngIf="!options.panelTitle" id="container" fxLayout="column">
  <div id="header" fxLayoutAlign="start start">
    <ng-content select="[additionalFilter]"></ng-content>
    <ng-container *ngTemplateOutlet="rangeSelectorTmpl"></ng-container>
  </div>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</div>

<mat-expansion-panel *ngIf="options.panelTitle" #panel [hideToggle]="options.hideToggle"
  [expanded]="options.panelClosed !== true" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
  (afterExpand)="afterExpand.emit()">
  <mat-expansion-panel-header>
    <mat-panel-title>{{options.panelTitle}}</mat-panel-title>
    <mat-panel-description *ngIf="panelOpenState" @fade>
      <div id="panel-description" fxLayoutAlign="end center">
        <ng-content select="[additionalFilter]"></ng-content>
        <ng-container *ngTemplateOutlet="rangeSelectorTmpl"></ng-container>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</mat-expansion-panel>

<ng-template #contentTmpl>
  <ng-content select="[content]"></ng-content>
</ng-template>

<ng-template #rangeSelectorTmpl>
  <div id="range-selector" fxLayout="row wrap" fxLayoutAlign="end center"
    (click)="$event.stopImmediatePropagation(); false">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="selectedZoom" (change)="changeZoom($event.value)">
      <mat-button-toggle *ngFor="let zoom of zooms" [checked]="zoom.key === selectedZoom" [value]="zoom.key"
        [aria-label]="zoom.label || zoom.key" color="primary">
        <mat-label>{{zoom.label || zoom.key}}</mat-label>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-form-field *ngIf="options.hideDatePicker !== true" no-subscript>
      <mat-label i18n>Période</mat-label>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="range" [max]="max">
        <input matStartDate formControlName="start" i18n-placeholder placeholder="date de début">
        <input matEndDate formControlName="end" i18n-placeholder placeholder="date de fin">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
</ng-template>
