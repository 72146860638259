/** @format */

import { PropertyMap } from 'model-mapper';
import { Embedded } from '../_decorators/embedded-class.decorator';
import { Color } from './color.class';
import { Location } from './location.class';
import { EmbeddedOrganizationalUnitKind } from './organizational-unit-kind.embedded.class';

@Embedded()
export class EmbeddedOrganizationalUnit {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedOrganizationalUnitKind })
  kind: EmbeddedOrganizationalUnitKind;

  @PropertyMap()
  reference: string;

  @PropertyMap({ type: Color })
  color: Color;

  @PropertyMap()
  name: string;

  @PropertyMap({ type: Location })
  location: Location;

  @PropertyMap()
  parent: string;

  @PropertyMap()
  path: string;
}
