/** @format */

import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Extremes } from '../../../_classes/extremes.class';
import { fade } from '../../../_constants/animations';

export interface IOptions {
  hideDatePicker?: boolean;
  panelTitle?: string;
  panelClosed?: boolean;
  hideToggle?: boolean;
}

interface IRangeElement {
  setExtremes(extremes: Extremes): void;
}

@Component({
  selector: 'app-chart-range-selector',
  templateUrl: './chart-range-selector.component.html',
  styleUrls: ['./chart-range-selector.component.scss'],
  animations: [fade],
})
export class ChartRangeSelectorComponent implements OnInit, AfterViewInit {
  @Output()
  afterExpand = new EventEmitter();

  @Input('options')
  set setOptions(options: IOptions) {
    if (options) this.options = options;
  }
  options: IOptions = {
    hideDatePicker: false,
  };

  max = moment();
  zooms: { key: string; label?: string }[] = [
    { key: moment().subtract(2, 'year').year().toString() },
    { key: moment().subtract(1, 'year').year().toString() },
    { key: moment().year().toString() },
    { key: '1-year', label: $localize`1 an` },
    { key: '6-months', label: $localize`6 mois` },
    { key: '3-months', label: $localize`3 mois` },
    { key: '1-month', label: $localize`1 mois` },
    { key: '1-week', label: $localize`1 semaine` },
    { key: 'today', label: $localize`Aujourd'hui` },
  ];
  public selectedZoom: string | null = 'today';
  public range = new FormGroup({
    start: new FormControl(moment().startOf('day')),
    end: new FormControl(moment().endOf('day')),
  });
  public panelOpenState = false;

  @Input('selectedZoom')
  set setSelectedZoom(zoom: string) {
    this.changeZoom(zoom);
  }

  @Output('changed')
  private changedEmitter = new EventEmitter<Extremes>();

  @ContentChildren('rangeElement', { descendants: true })
  private elements?: QueryList<IRangeElement>;

  private subsink = new SubSink();

  constructor() {}

  ngOnInit() {
    this.panelOpenState = this.options?.panelClosed !== true;
    let previousValue = this.range.value;
    this.changedEmitter.emit(new Extremes(previousValue.start?.startOf('day'), previousValue.end?.endOf('day')));
    this.subsink.add(
      this.range.valueChanges
        .pipe(
          filter((value) => {
            if (
              previousValue?.start?.valueOf() === value?.start?.valueOf() &&
              previousValue?.end?.valueOf() === value?.end?.valueOf()
            ) {
              return false;
            }
            previousValue = value;
            return true;
          }),
          tap(() => (this.selectedZoom = null)),
          debounceTime(300),
          tap((value) => {
            if (!value.start || !value.end) return;
            const extreme = new Extremes(value.start?.startOf('day'), value.end?.endOf('day'));
            this.elements?.forEach((chart) =>
              typeof chart.setExtremes === 'function' ? chart.setExtremes(extreme) : null,
            );
            this.changedEmitter.emit(extreme);
          }),
        )
        .subscribe(),
    );
  }

  ngAfterViewInit(): void {
    this.updateElements();
    this.subsink.add(this.elements?.changes.subscribe((elements: QueryList<IRangeElement>) => this.updateElements()));
  }

  changeZoom(zoom: string): void {
    let rangeValue: { start: Moment; end: Moment };
    switch (zoom) {
      case moment().subtract(2, 'year').year().toString():
        rangeValue = {
          start: moment().subtract(2, 'year').startOf('year'),
          end: moment().subtract(2, 'year').endOf('year'),
        };
        break;
      case moment().subtract(1, 'year').year().toString():
        rangeValue = {
          start: moment().subtract(1, 'year').startOf('year'),
          end: moment().subtract(1, 'year').endOf('year'),
        };
        break;
      case moment().year().toString():
        rangeValue = {
          start: moment().startOf('year'),
          end: moment().endOf('day'),
        };
        break;
      case 'today':
        rangeValue = {
          start: moment().startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '1-week':
        rangeValue = {
          start: moment().subtract(1, 'week').add(1, 'day').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '1-month':
        rangeValue = {
          start: moment().subtract(1, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '3-months':
        rangeValue = {
          start: moment().subtract(3, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '6-months':
        rangeValue = {
          start: moment().subtract(6, 'month').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      case '1-year':
        rangeValue = {
          start: moment().subtract(1, 'year').startOf('day'),
          end: moment().endOf('day'),
        };
        break;
      default:
        return;
    }
    this.range.setValue(rangeValue);
    this.selectedZoom = zoom;
  }

  private updateElements() {
    const value = this.range.value;
    const extrtemes = new Extremes(value.start?.startOf('day'), value.end?.endOf('day'));
    this.elements?.forEach((elmt) => (typeof elmt.setExtremes === 'function' ? elmt.setExtremes(extrtemes) : null));
  }
}
