/** @format */

import { Injectable, inject } from '@angular/core';
import { Moment } from 'moment';
import { Observable, from } from 'rxjs';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { HttpService } from './http.service';

export interface ISpeedChartData {
  timestamp: number;
  min: number;
  max: number;
  avg: number;
}

export interface IMouvementDataFilter {
  equipmentId?: string;
  sesioNodeComponentId?: string;
  from?: Date | Moment;
  to?: Date | Moment;
  grouping?: { unit: string };
}

@Injectable({
  providedIn: 'root',
})
export class MouvementDataService {
  path = '/mouvement-data';
  httpService: HttpService = inject(HttpService);

  async getFloorChartData(equipmentId: string, from?: Moment | Date, to?: Moment | Date): Promise<[[number, number]]> {
    const path = `${this.path}/floor-chart-data`;
    const query = { equipmentId, from: from?.toISOString(), to: to?.toISOString() };
    return this.httpService.get(path, query);
  }

  async getSpeedChartData(equipmentId: string, from?: Moment, to?: Moment): Promise<ISpeedChartData[]> {
    const path = `${this.path}/speed-chart-data`;
    const query = { equipmentId, from: from?.clone().utc(true).toISOString(), to: to?.clone().utc(true).toISOString() };
    return this.httpService.get(path, query);
  }

  async getFloorAttendance(
    equipmentId: string,
    from?: Moment,
    to?: Moment,
  ): Promise<{ name: string; shortName: string; index: number; count: number }[]> {
    const path = `${this.path}/floor-attendance`;
    const query = { equipmentId, from: from?.clone().utc(true).toISOString(), to: to?.clone().utc(true).toISOString() };
    return this.httpService.get(path, query);
  }

  async getMouvementChartData(filter: IMouvementDataFilter): Promise<{ name: number; value: number }[]> {
    const path = `${this.path}/mouvement-chart-data`;
    return this.httpService.post(path, this.getFilterQuery(filter));
  }

  async getSpeedAverage(filter: IMouvementDataFilter): Promise<number | null> {
    const path = `${this.path}/speed-average`;
    return this.httpService.post(path, this.getFilterQuery(filter));
  }

  datatable(query: IDatatableOptions, filter?: IMouvementDataFilter): Observable<any> {
    const path = `${this.path}/datatable`;
    return from(this.httpService.post(path, { query, filter: this.getFilterQuery(filter) }));
  }

  getFilterQuery(filter?: IMouvementDataFilter): any {
    const query: any = {};
    if (filter?.equipmentId) query.equipmentId = filter.equipmentId;
    if (filter?.sesioNodeComponentId) query.sesioNodeComponentId = filter.sesioNodeComponentId;
    if (filter?.from) query.from = filter.from.toISOString();
    if (filter?.to) query.to = filter.to.toISOString();
    if (filter?.grouping) query.grouping = filter.grouping;
    return query;
  }
}
