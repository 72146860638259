/** @format */

import { PropertyMap } from 'model-mapper';
import { FieldUpdateOrigin } from './field-update-origin.class';

export class FieldHistory {
  @PropertyMap()
  _id: string;

  @PropertyMap()
  organization: string;

  @PropertyMap()
  entityId: string;

  @PropertyMap()
  collectionName: string;

  @PropertyMap()
  entityName: string;

  @PropertyMap()
  field: string;

  @PropertyMap()
  value: any;

  @PropertyMap({ type: FieldUpdateOrigin })
  origin: FieldUpdateOrigin;

  @PropertyMap({ type: Date })
  start: Date;

  @PropertyMap({ type: Date })
  end: Date;

  @PropertyMap({ default: false })
  isIgnored: boolean;

  @PropertyMap()
  timezone: string;

  @PropertyMap()
  metadata: any;
}
