import { Injectable } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { Moment } from 'moment';
import { NGXLogger } from 'ngx-logger';
import { map, merge } from 'lodash-es';
import { HttpService } from './http.service';
import { FieldHistory } from '../_classes/field-history.class';
import { Extremes } from '../_classes/extremes.class';

export interface EquipmentProperty {
  equipmentID: string;
  time: Moment;
  propertyName: string;
  value: any;
  createdAt: Moment;
  updatedAt: Moment;
}

@Injectable({
  providedIn: 'root',
})
export class FieldHistoryService {
  protected pathBase = '/field-history';

  constructor(private service: HttpService, private logger: NGXLogger) {}

  async get(entityId: string, field: string, extremes?: Extremes): Promise<FieldHistory[]> {
    const path = `${this.pathBase}`;
    const query = { entityId, field };
    if (extremes) merge(query, extremes.toQueryStringParameters());
    return this.service.get(path, query).then((data) => map(data, (d) => new ModelMapper(FieldHistory).map(d)));
  }
}
